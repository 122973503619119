import axios from "axios"
import CryptoJS from "crypto-js"
import moment from "moment-timezone"
import { EViewState } from "./App"
import config from "./config"

export enum REQUEST_METHODS {
  get = "get",
  post = "post",
  patch = "patch",
  delete = "delete",
}

const requestInstance = axios.create({
  baseURL: config.baseUrl,
  timeout: 60000,
})

const requestRetryWrapper = (
  ajaxRequest: any,
  url: any,
  method: REQUEST_METHODS
) =>
  new Promise(async (resolve, reject) => {
    const secondRetry = async () => {
      try {
        const retry = await ajaxRequest()
        console.log(`${method} - ${url} - second retry success`)
        return resolve(retry)
      } catch (error) {
        console.log(`${method} - ${url} - second retry failed`)
        return reject(error)
      }
    }

    const firstRetry = async () => {
      try {
        const retry = await ajaxRequest()
        console.log(`${method} - ${url} - first retry success`)
        return resolve(retry)
      } catch (error) {
        console.log(`${method} - ${url} - first retry failed`)
        await secondRetry()
      }
    }

    try {
      const response = await ajaxRequest()
      console.log(`${method} - ${url} - success`)
      // eslint-disable-next-line no-promise-executor-return
      return resolve(response)
    } catch (error) {
      console.log(`${method} - ${url} - failed`)
      await firstRetry()
    }
  })

const getRequestHeaders = ({
  idToken,
  date,
}: {
  idToken: string
  date: string
}) => {
  return {
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${idToken}`,
      "X-Install-Id": "X-Install-Id",
      // "Accept-Language": i18n.language,
      "X-Timezone": moment.tz.guess(),
      "X-Platform": "WEB",
      "X-Inapp-Client": "WEB",
      "X-Version-Name": "1.0.0", // TODO
      "X-Version-Number": 0, // TODO - version number
      "X-date": date,
    },
  }
}

const getIdToken = async ({ date }: { date: string }) => {
  try {
    // get the timestampe from the request and format it "hh:mm:ss aaa, yyyy, MMM dd" and us Locale
    const localMoment = moment.utc(date).locale("en")
    const d = localMoment.format("hh:mm:ss A, YYYY, MMM DD")
    // get project data base url, split that url String with '2' regex & use only the first substring.
    const dbURL = config.firebase.databaseURL.split("2")[0]
    const auth = CryptoJS.HmacSHA256(d, dbURL).toString(CryptoJS.enc.Hex)
    return auth
  } catch (error) {
    return ""
  }
}

const requestWrapper = async ({
  url,
  params,
  method,
}: {
  url: string
  params?: any
  method: REQUEST_METHODS
}) => {
  try {
    const localMoment = moment.utc().locale("en")
    const DATE_RFC2822 = "ddd, DD MMM YYYY HH:mm:ss [GMT]"
    const date = localMoment.format(DATE_RFC2822)

    const idToken = await getIdToken({ date })
    const headers = getRequestHeaders({ idToken, date })

    const body = {
      ...params,
    }

    switch (method) {
      case REQUEST_METHODS.get:
        return requestRetryWrapper(
          async () => requestInstance.get(url, headers),
          url,
          method
        )
      case REQUEST_METHODS.post:
        return requestRetryWrapper(
          async () => requestInstance.post(url, body, headers),
          url,
          method
        )
      case REQUEST_METHODS.patch:
        return requestRetryWrapper(
          async () => requestInstance.patch(url, params, headers),
          url,
          method
        )
      case REQUEST_METHODS.delete:
        return requestRetryWrapper(
          async () => requestInstance.delete(url, headers),
          url,
          method
        )

      default:
        return requestRetryWrapper(
          async () => requestInstance.get(url, headers),
          url,
          method
        )
    }
  } catch (error) {
    console.error(error)
    return Promise.reject(error)
  }
}

const getRemoteLinkData = async ({
  d10xLinksBranding,
  linkId,
}: {
  d10xLinksBranding: string
  linkId: string
}) => {
  const mappingRes = await fetch(`${process.env.PUBLIC_URL}/brndtoom.json`)
  const mapping = await mappingRes.json()

  const orgIdKey = `B${CryptoJS.SHA1(d10xLinksBranding).toString()}`
  const orgId = mapping[orgIdKey as keyof typeof mapping]

  const anonymous_id = localStorage.getItem(orgId)
  let iAnonParam = ""
  if (anonymous_id) {
    iAnonParam = `&anonymous_id=${anonymous_id}`
  } else {
    // check if brand mapping on local storage
    // if yes, replace with orgId mapping
    const brand_anonymous_id = localStorage.getItem(d10xLinksBranding)
    if (brand_anonymous_id) {
      localStorage.removeItem(d10xLinksBranding)
      localStorage.setItem(orgId, brand_anonymous_id)
      iAnonParam = `&anonymous_id=${brand_anonymous_id}`
    }
  }

  // get current params without the "?"
  let params = window.location.search.substring(1)
  if (iAnonParam) {
    params = `&${params}`
  }

  const res: any = await requestWrapper({
    url: `/d10xLink/link_data/${orgId}/${linkId}?${iAnonParam}${params}`,
    method: REQUEST_METHODS.get,
  })

  const data = res.data as {
    anonymousId: string
    orgId: string
    redirectLink: string
  }

  if (data && data.anonymousId && data.orgId) {
    localStorage.setItem(data.orgId, data.anonymousId)
  }

  return data
}

const getUrlInfo = () => {
  const urlParts = window.location.pathname.split("/").slice(1)

  if (urlParts.length < 2) {
    clearHistoryAndNavigate("https://www.dynamo.fyi/")
  }

  return {
    d10xLinksBranding: urlParts[0],
    linkId: urlParts[1],
  }
}

const getData = async ({
  d10xLinksBranding,
  linkId,
}: {
  d10xLinksBranding: string
  linkId: string
}): Promise<{ state: EViewState; data?: string }> => {
  try {
    const link = await getRemoteLinkData({
      d10xLinksBranding,
      linkId,
    })

    if (link && link.redirectLink) {
      // return { state: EViewState.continue, data: link.redirectLink }
      clearHistoryAndNavigate(link.redirectLink)
    }

    return { state: EViewState.continue }
  } catch (error: any) {
    // if super link expired
    if (error.response.status === 410) {
      return { state: EViewState.expired }
    }
    return { state: EViewState.error }
  }
}

const init = async (): Promise<{ state: EViewState; data?: string }> => {
  try {
    const {
      d10xLinksBranding,
      linkId,
    }: {
      d10xLinksBranding: string
      linkId: string
    } = getUrlInfo()

    return await getData({
      d10xLinksBranding,
      linkId,
    })
  } catch (error) {
    return { state: EViewState.error }
  }
}

// const createIFrame = () => {
//   const iframe = document.createElement("iframe")
//   iframe.style.border = "none"
//   iframe.style.width = "1px"
//   iframe.style.height = "1px"
//   iframe.setAttribute("style", "display:none;")

//   return iframe
// }

const clearHistoryAndNavigate = (url: string) => {
  window.location.href = url

  // const iframe = createIFrame()

  // iframe.onload = () => {
  //   iframe.parentNode?.removeChild(iframe)
  //   window.location.href = url
  // }

  // iframe.src = url
  // document.body.appendChild(iframe)
}

export { init, clearHistoryAndNavigate }
